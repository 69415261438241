
.Tile {
  background-color: white;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin-top: 10px;
  margin-bottom: 10px;
  box-shadow: -2px 2px 8px 2px #f3f3f3;
  min-height: 160px;
}

.TileHeader {
  color: #fdfdfd;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 5px;
  padding: 10px 20px;
  border-radius: 0.25rem 0.25rem 0 0;
  background: linear-gradient(
    90dEg,
    var(--BotanacorGreen90) 60%,
    var(--GradientBlue90) 100%
  );
}

.TileDivider {
  margin: 0;
}

.TileBody {
  margin-top: 15px;
  padding: 10px 20px 25px 20px;
}

.DefaultHeader {
  color: #fdfdfd;
  font-size: 24px;
  font-weight: 400;
}

.Card:hover {
  box-shadow: 0 2px 8px 2px #e6e6e6;
}

.CardButton {
  width: 100%;
  height: 100%;
}

.CardButton svg {
  width: 100%;
  height: 100%;
}

.CardIcon {
  width: 70% !important;
  height: 70%;
  color: #2b6ab4;
}


.OrderControls {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.OrderControls button {
  margin: 0 7px;
  width: 42px;
}

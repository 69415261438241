
.ChildContainer {
  min-height: 50vh;
  padding: 20px;
  border: 1px solid transparent;
  border-radius: 0 0 0.5rem 0.5rem;
  box-shadow: 0 4px 12px 0 #ebebeb;
}

.TabRow {
  display: flex;
  flex-flow: wrap;
  align-items: flex-end;
  border-bottom: 1px solid #eeeeee;
  margin: 0;
}

.Tab {
  min-width: 130px;
  min-height: 40px;
  border: 1px solid #e6e6e6;
  border-bottom: none;
  border-radius: 0.5rem 0.5rem 0 0;
  background: #fafafa;
  box-shadow: inset 0 -5px 18px -10px #c7c7c7;
}

.Tab.Active {
  min-height: 45px;
  background: #fdfdfd;
  box-shadow: 0 0px 0px 0 #d7d7d7;
  border: 1px solid #f1f1f1;
  border-bottom: 0 solid;
}

.Tab.Active button {
  color: var(--BotanacorGreen);
  font-weight: 600;
  min-height: 45px;
  box-shadow: none;
}

.Tab button {
  font-size: 16px;
  font-weight: 400;
  width: 100%;
  min-height: 40px;
}

.Tab button:focus, .BotanacorButton.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 150, 121, 0.5);
}

.Tab button:not(:disabled):not(.disabled):active:focus,
.Tab button:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 150, 121, 0.51);
}

.Tab.Active button:not(:disabled):not(.disabled):active:focus,
.Tab.Active button:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 150, 121, 0.51);
}

.Tab:first-of-type {
  border-width: 2px 1px 0 2px;
}

.Tab:last-of-type {
  border-width: 2px 2px 0 1px;
}



.ContactsTable thead th {
    text-align: center;
}

.ContactsTable thead th:nth-of-type(1), 
.ContactsTable thead th:nth-of-type(2),
.ContactsTable thead th:nth-of-type(3) {
    text-align: left;
}

.ContactsTable tbody td {
    text-align: center;
    color: var(--GradientBlue100);
}

.ContactsTable tbody td:nth-of-type(1),
.ContactsTable tbody td:nth-of-type(2),
.ContactsTable tbody td:nth-of-type(3) {
    text-align: left;
    color: black;
}
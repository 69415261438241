
.Logo {
  background-color: transparent;
  padding: 4px;
  height: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 20px;
}

.Logo img {
  height: 115%;
}


.SampleDisplay {
  margin-bottom: 8px;
}

.SampleHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-weight: 400;
  padding: 8px;
}

.SampleHeaderDivider {
  width: 100%;
  height: 5px;
  padding: -8px;
  border-radius: 0.25rem;
  background: linear-gradient(
    90dEg,
    var(--BotanacorGreen50) 60%, 
    var(--GradientBlue50) 100%
  );
}

.SampleNumber {
  font-size: 20px;
  font-weight: 300;
  margin-right: 10px;
  display: inline-flex;
}

.SampleTitle {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.SampleName {
  font-size: 22px;
  margin-right: 10px;
  flex-wrap: wrap;
  color: #008264;
}

.BatchName {
  font-size: 18px;
  margin-right: 10px;
  font-style: italic;
  flex-wrap: wrap;
  white-space: pre-wrap;
  color: #4a4a4a;
}

.ComplianceType {
  font-size: 18px;
  color: #909090;
}

.ProductType {
  font-size: 18px;
  color: #909090;
}

.StatusType {
  font-size: 18px;
  color: #909090;
}

.SamplerName {
  font-size: 22px;
  margin-right: 10px;
  flex-wrap: wrap;
  color: #008264;
}

.SamplerID {
  font-size: 22px;
  margin-right: 10px;
  flex-wrap: wrap;
  color: #008264;
}

.USDALicense  {
  font-size: 22px;
  margin-right: 10px;
  flex-wrap: wrap;
  color: #008264;
}

.SampleButtons {
  flex-wrap: nowrap;
  justify-self: flex-end;
  margin-left: 10px;
}

.SummaryButton {
  min-width: 50px;
  min-height: 35px;
  margin: 0 8px;
}

.TestsContainer {
  padding: 8px;
  border: 1px solid #f1f1f1;
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
}

.TestBox {
  padding-top: 3px;
  padding-bottom: 5px;
  border: 1px solid #f5f5f5;
  border-radius: 0.25rem;
  margin: 4px;
}


.DrawerToggle {
  width: 30px;
  height: 75%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  box-sizing: border-box;
  cursor: pointer;
}

.DrawerToggle div {
  width: 90%;
  height: 2px;
  background-color: #909392;
  border-radius: 0.5rem;
}

@media (min-width: 900px) {
  .DrawerToggle {
    display: none;
  }
}


.OrdersTile {
  min-height: 200px;
}

.OrdersTileBody {
  min-height: 200px;
  overflow: auto;
  margin-bottom: 25px;
}

.OrdersTable thead tr th:first-of-type {
  color: #b4b4b4;
  width: 150px;
}

.OrdersTable th {
  font-weight: 400;
  font-size: 18px;
  padding: 5px;
}

.OrdersTable td, .OrdersTable th {
  border: none;
  text-align: center;
}

.OrdersTable tbody:before {
  content: '.';
  color: transparent;
  line-height: 0.25rem;
  display: block;
}

.OrdersTable tbody tr {
  border-bottom: 1px solid #e6e6e6;
}

.OrdersTable tbody tr:last-of-type {
  border-bottom: none;
}

.OrdersTable tbody td {
  text-align: center;
}

.OrdersTable tbody tr td:first-of-type {
  display: flex;
  padding: 5px;
}

.DatePickerSpace {
  margin-left: 5px;
}

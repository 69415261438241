
.Modal {
  position: fixed;
  z-index: 500;
  background-color: white;
  width: 90%;
  border: 1px solid #ccc;
  box-shadow: 0 2px 8px #303030;
  border-radius: 0.5rem;
  padding: 16px 16px 100px 16px;
  top: 2%;
  left: 5%;
  max-height: 95vh;
  overflow: auto;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
}

.ModalCloseButton {
  min-height: 40px;
  min-width: 40px;
  margin-right: 15px;
  font-size: 20px;
  font-weight: bold;
  padding: 4px;
  color: #6c757d;
}

@media(min-width: 1325px) {
  .Modal {
    --modal-width: 1180px;
    width: var(--modal-width);
    max-height: 95vh;

    /* center the modal*/
    left: calc(50% - var(--modal-width)/2);
  }
}

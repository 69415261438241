
.TestEntrySection {
  margin-top: 15px;
  background-color: #f7f7f7;
  border: 1px solid #e3e3e3;
  padding-bottom: 12px;
  border-radius: 5px;
  box-shadow: 0 1px 8px 0 #dfe1dd;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.TestSelectionGroup {
  margin: 10px 0 0 10px;
}

.ValidationMsg {
  color: red;
  font-style: italic;
}

.SectionDesc {
  /* border-bottom: 1px solid black; */
  font-size: 20px;
  font-weight: 500;
  color: black;
}

.DensityUnknownCheck {
  margin-left: 40%;
  margin-top: 8px;
  display: block;
  padding: 16px;
  width: 16px;
  height: 16px;
}

.DisabledTestRow {
  background-color: #f5f5f5;
  color: #8f8f8f
}

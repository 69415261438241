
:root {
  --BotanacorGreen: #00ab8e; 
  --BotanacorGreen100: rgba(0, 171, 143, 1);
  --BotanacorGreen90: rgba(0, 171, 143, 0.9);
  --BotanacorGreen80: rgba(0, 171, 143, 0.8);
  --BotanacorGreen70: rgba(0, 171, 143, 0.7);
  --BotanacorGreen60: rgba(0, 171, 143, 0.6);
  --BotanacorGreen50: rgba(0, 171, 143, 0.5);
  --BotanacorGreen40: rgba(0, 171, 143, 0.4);

  --GradientBlue100: rgba(0, 160, 175, 1);
  --GradientBlue90: rgba(0, 160, 175, 0.9);
  --GradientBlue80: rgba(0, 160, 175, 0.8);
  --GradientBlue70: rgba(0, 160, 175, 0.7);
  --GradientBlue60: rgba(0, 160, 175, 0.6);
  --GradientBlue50: rgba(0, 160, 175, 0.5);
  --GradientBlue40: rgba(0, 160, 175, 0.4);
}

body {
  background-color: #fdfdfd;
  margin-bottom: 50px;
}

.BtnSM {
  font-size: 18px;
  margin: 10px;
  min-width: 125px;
  min-height: 40px;
  padding: 10px;
  box-shadow: -2px 2px 8px 2px #f3f3f3;
}

.BtnMd {
  font-size: 18px;
  margin: 10px;
  min-width: 125px;
  min-height: 40px;
  padding: 10px;
  box-shadow: -2px 2px 8px 2px #f3f3f3;
}

.BtnLg {
  font-size: 18px;
  margin: 10px;
  min-width: 155px;
  min-height: 55px;
  padding: 10px;
  box-shadow: -2px 2px 8px 2px #f3f3f3;
}

.BotanacorButton {
  color: #fff;
  background-color: #00ab8e;
  border-color: #00ab8e;
}

.BotanacorButton:hover {
  color: #fff;
  background-color: #009679;
  border-color: #009679;
}

.BotanacorButton:focus, .BotanacorButton.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 150, 121, 0.5);
}

.BotanacorButton.disabled, .BotanacorButton:disabled {
  color: #fff;
  background-color: #40827a;
  border-color: #40827a;
}

.BotanacorButton:not(:disabled):not(.disabled):active,
.BotanacorButton:not(:disabled):not(.disabled).active,
.show > .BotanacorButton.dropdown-toggle {
  color: #fff;
  background-color: #009679;
  border-color: #409189;
}

.BotanacorButton:not(:disabled):not(.disabled):active:focus,
.BotanacorButton:not(:disabled):not(.disabled).active:focus,
.show > .BotanacorButton.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 150, 121, 0.51);
}

.BotanacorButtonLight {
  color: #00ab8e;
  background-color: white;
  border-color: #00ab8e;
}

.BotanacorButtonLight:hover {
  color: #fff;
  background-color: #009679;
  border-color: #009679;
}

.BotanacorButtonLight:focus, .BotanacorButtonLight.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 150, 121, 0.5);
}

.BotanacorButtonLight.disabled, .BotanacorButtonLight:disabled {
  color: #fff;
  background-color: #40827a;
  border-color: #40827a;
}

.BotanacorButtonLight:not(:disabled):not(.disabled):active,
.BotanacorButtonLight:not(:disabled):not(.disabled).active,
.show > .BotanacorButtonLight.dropdown-toggle {
  color: #fff;
  background-color: #009679;
  border-color: #409189;
}

.BotanacorButtonLight:not(:disabled):not(.disabled):active:focus,
.BotanacorButtonLight:not(:disabled):not(.disabled).active:focus,
.show > .BotanacorButtonLight.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 150, 121, 0.51);
}

.CancelButton {
  color: #fff;
  background-color: #e66767;
  border-color: #e66767;
}

.CancelButton:hover {
  color: #fff;
  background-color: #d26868;
  border-color: #d26868;
}

.CancelButton:focus, .CancelButton.focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 104, 104, 0.51);
}

.CancelButton.disabled, .CancelButton:disabled {
  color: #fff;
  background-color: #b47673;
  border-color: #b47673;
}

.CancelButton:not(:disabled):not(.disabled):active,
.CancelButton:not(:disabled):not(.disabled).active,
.show > .CancelButton.dropdown-toggle {
  color: #fff;
  background-color: #d26868;
  border-color: #d26868;
}

.CancelButton:not(:disabled):not(.disabled):active:focus,
.CancelButton:not(:disabled):not(.disabled).active:focus,
.show > .CancelButton.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(210, 104, 104, 0.51);
}

.BtnRound {
  border-radius: 3rem;
}

.InpGroupBtn {
  border-radius: 0 0.25rem 0.25rem 0;
}

.GroupInpL {
  border-radius: 0.25rem 0 0 0.25rem;
}

label.ReqInput:after,
.ReqInput label:after {
  content: " *";
  color: #00ab8e;
}

.AdminContent {
  position: relative;
  padding: 10px 10px;
  margin: 10px;
  border: 1px solid rgba(9, 126, 186, 0.25);
  border-radius: 0.5rem;
  color: #2b81cd;
}

.AdminContent::after {
  position: absolute;
  right: 1%;
  top: 1%;
  font-style: italic;
  font-weight: 500;
  color: rgba(9, 126, 186, 0.25);
  content: 'Admin';
}

.InvalidInput {
  color: red;
  margin-bottom: 0.25rem;
  font-style: italic;
}

.Display5 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.Display6 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.tippy-tooltip.botanacor-theme {
  background: linear-gradient(
    135deg,
    var(--GradientBlue100) 0%,
    var(--BotanacorGreen100) 60% 
  );
  color: white;
  font-weight: 500;
}

.tippy-tooltip.botanacor-theme[data-placement^='top'] .tippy-arrow {
  border-top-color: rgb(46, 172, 151);
}
.tippy-tooltip.botanacor-theme[data-placement^='bottom'] .tippy-arrow {
  border-bottom-color: rgb(46, 172, 151);
}
.tippy-tooltip.botanacor-theme[data-placement^='left'] .tippy-arrow {
  border-left-color: rgb(46, 172, 151);
}
.tippy-tooltip.botanacor-theme[data-placement^='right'] .tippy-arrow {
  border-right-color: rgb(46, 172, 151);
}

.BotanacorBackground {
  background: #00ab8e;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -2;
  opacity: 0.9;
}

.GradientBotanacorToBlue {
  background: linear-gradient(90deg, #00ab8e 0%, rgb(0, 160, 175) 100%);
  opacity: 0.9;
}

.SelectArrowNone {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}
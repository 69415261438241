.LoginBoxContainer {
  height: 600px;
  width: 420px;
  max-width: 95vw;
  border-radius: .75rem;
  background: #00ab8e;
  background: url("/grid-wave.png");
  background: url("/grid-wave.png") no-repeat -850px 200px,
    linear-gradient(30deg, #00ab8e 10%, #0094ba 100%);
}

.SignInHeader {
  padding: 20px;
  font-size: 24px;
  color: white;
  text-align: center;
}

.BotanacorLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60px;
  padding-top: 50px;
}

.BotanacorBLogo {
  margin-top: 20px;
  width: 300px;
  height: 72px;
}

.LoginForm {
  color: white;
}
.LoginForm button:global(#login-button) {
  font-size: 18px;
  width: 300px;
}

.LoginForm label {
  color: white;
  margin-bottom: 2px;
}

.LoginForm input {
  color: white;
  background-color: rgba(255, 255, 255, 0.35);
  border: none;
}
.LoginForm input:focus {
  background-color: rgba(255, 255, 255, 0.35);
  color: white;
}
.LoginForm input::placeholder {
  color: #f0f0f0;
  font-style: italic;
  font-size: 14px;
}

.LoginForm div:global(.input-group-prepend) {
  border: none;
}

.LoginForm span:global(.input-group-text) {
  color: white;
  background-color: rgba(255, 255, 255, 0.35);
  border: none;
}

.LoginForm span:global(#auth-error-msg) {
  font-weight: bold;
}

.LoginForm a {
  color: white;
  text-decoration: underline;
}

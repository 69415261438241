
.ContinueButtonRow {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.ContinueOrderButton {
  font-size: 18px;
  margin: 25px 0 50px 0;
  min-width: 155px;
  min-height: 55px;
  padding: 10px;
  box-shadow: 0 2px 15px 2px #d2d2d2;
}


.TestInfo {
  font-size: 18px;
  padding: 0 8px;
}

.TurnTimeAndCount {
  font-size: 16px;
  white-space: nowrap;
  color: #909090;
}

.TestIcon {
  color: #909090;
}

.ResultDate {
  font-weight: 400;
  font-size: 15px;
  margin-left: 16px;
  padding-left: 8px;
}

.AddnlParam {
  font-size: 14px;
}
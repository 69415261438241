
.PricingTable th:first-of-type,
.PricingTable th:nth-of-type(2){
  text-align: left;
}

.PricingTable th {
  text-align: center;
}

.PricingTable td:first-of-type,
.PricingTable td:nth-of-type(2) {
  text-align: left;
}

.PricingTable td {
  text-align: center;
}

.CompanySelect {
  border: 1px solid #cdcdcd;
  min-width: 150px;
  max-width: 300px;
  height: 30px;
  border-radius: 0.25rem;
}

.SelectGroup {
  margin: 3px 0;
  display: flex;
  white-space: nowrap;
}

.HeaderText {
  font-weight: 300;
  color: grey;
  vertical-align: middle;
  font-size: 22px;
}

.EditButton {
  height: 30px;
  margin-left: 8px;
  padding: 3px 6px;
  color: #00ab8e;
  text-align: center;
}


.Collapse {
  width: 100%;
}

.CollapseHeader {
  width: 100%;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: #efefef;
}

.HeaderIcon {
  width: 24px !important;
  height: 24px !important;
  color: #9b9b9b;
}

.CollapseBody {
  background-color: white;
  border: 1px solid #ebebeb;
  padding: 20px 20px;
  border-radius: 0 0 0.5rem 0.5rem;
  margin-bottom: 10px;
  box-shadow: -2px 2px 8px 2px #f3f3f3;
}

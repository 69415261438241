
.NavigationItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  box-shadow: -2px 2px 8px 1px #e8ebea;
  border-radius: 0.25rem;
  width: 100%;
}

.NavigationItem a {
  color: #909392;
  font-weight: 500;
  padding: 6px;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
  color: #00ab8e;
}

.CurrentUser {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
}

.CurrentUser span {
  color: #00ab8e;
  text-transform: uppercase;
}

@media (min-width: 900px) {
  .NavigationItem {
    margin: 0;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
    box-shadow: none;
  }

  .NavigationItem a {
    color: #909392;
    font-weight: 500;
    text-decoration: none;
    font-size: 14px;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
  }

  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    background-color: white;
    color: #909392;
    font-weight: 500;
  }

  .NavigationItem a:hover:after,
  .NavigationItem a:active:after,
  .NavigationItem a.active:after {
    transform: scaleX(1);
  }

  .CurrentUser {
    margin: 0 15px 0 0 ;
    display: flex;
    height: 100%;
    width: auto;
    align-items: center;
  }

  .CurrentUser span {
    color: #00ab8e;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
  }
}

.NavigationItem a:after {
  display: block;
  content: '';
  border-bottom: solid 2px #00ab8e;
  transform: scaleX(0);
  transition: transform 350ms ease-in-out;
  border-radius: 0.25rem;
}

.Card {
  display: flex;
  flex-direction: column;
  flex-basis: 275px;
  border: 1px solid #e6e6e6;
  border-radius: 0.25rem;
  box-shadow: -2px 2px 8px 2px #f3f3f3;
}

.CardHeader {
  display: flex;
  flex-basis: 85px;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  border-bottom: 1px solid #dcdcdc;
}

.CardBody {

}

.CardFooter {
  flex-grow: 1;
  justify-self: flex-end;
  padding: 4px;
  border-top: 1px solid #dcdcdc;
}

.DatePickerNote {
  color: #00ae8b;
  font-size: 19px;
  font-weight: 300;
}

.SummaryBox {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 65vh;
}